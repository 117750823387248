<template>
  <Layout>
    <div class="row mt-2 mb-5">
      <div class="offset-lg-4 col-lg-4 col-12">
        <BootstrapAlert/>
        <div class="pricing-card mt-5 p-4">
          <h1 class="text-center mb-3">{{usDollar(this.price.unit_amount/100)}}<small>/{{this.price.interval}}</small></h1>

          <ul class="list-unstyled vstack gap-3">
            <li>
              <div class="d-flex">
                <div class="flex-shrink-0 text-success me-1">
                  <i class="ri-checkbox-circle-fill fs-15 align-middle"></i>
                </div>
                <div class="flex-grow-1">
                  State
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <div class="flex-shrink-0 text-success me-1">
                  <i class="ri-checkbox-circle-fill fs-15 align-middle"></i>
                </div>
                <div class="flex-grow-1">
                  Zip Code
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <div class="flex-shrink-0 text-success me-1">
                  <i class="ri-checkbox-circle-fill fs-15 align-middle"></i>
                </div>
                <div class="flex-grow-1">
                  Business Type
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <div class="flex-shrink-0 text-success me-1">
                  <i class="ri-checkbox-circle-fill fs-15 align-middle"></i>
                </div>
                <div class="flex-grow-1">
                  Employees/Jobs
                </div>
              </div>
            </li>

            <li>
              <div class="d-flex">
                <div class="flex-shrink-0 text-success me-1">
                  <i class="ri-checkbox-circle-fill fs-15 align-middle"></i>
                </div>
                <div class="flex-grow-1">
                  Company Name
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <div class="flex-shrink-0 text-success me-1">
                  <i class="ri-checkbox-circle-fill fs-15 align-middle"></i>
                </div>
                <div class="flex-grow-1">
                  PPP Loan No
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <div class="flex-shrink-0 text-success me-1">
                  <i class="ri-checkbox-circle-fill fs-15 align-middle"></i>
                </div>
                <div class="flex-grow-1">
                  Address
                </div>
              </div>
            </li>
          </ul>

          <div class="mb-3">
            <h5 class="mb-0">Enter your card details.</h5>
          </div>
          <div ref="card" class="inputCard"/>
          <div id="card-errors" role="alert" class="red-color"/>
          <div v-if="alert" class="red-color">
            <span>{{ this.alertTxt }}</span>
          </div>
          <div class="mt-3 pt-2">
            <a href="javascript:void(0);" @click="this.check_payment" class="btn purchase w-100 cursor-pointer">Pay with Stripe</a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main";
import PostService from "@/apis/stripe-services";
import BootstrapAlert from "@/components/utils/Alert";
import {mapActions} from "vuex";
import Utill from "@/utils/functions";


const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY, {
  locale: "en"
});
// Create an instance of Elements.
const elements = stripe.elements()
const style = {
  base: {
    color: "#004ABB",
    iconColor: "#004ABB",
    fontFamily: "'Mukta', Helvetica, sans-serif",
    fontWeight: "600",
    fontSmoothing: "antialiased",
    fontSize: "16px",
    "::placeholder": {
      color: "#6099EE",
      textTransform: "uppercase",
    },
  },
  invalid: {
    color: "#ff5252",
    iconColor: "#ff5252",
  },
};

const card = elements.create('card', { style: style })

export default {
  name: "stripe-checkout",
  components: {
    Layout,
    BootstrapAlert
  },

  data(){
    return{
      valid: false,
      alert: false,
      loading: false,
      alertTxt: '',
      currentUser: JSON.parse(localStorage.getItem("userdata")),
      price: {
        currency: "",
        interval: "",
        unit_amount: ""
      }
    }
  },

  mounted() {
    card.mount(this.$refs.card)
    card.on('change', (event) => {
      this.displayError(event)
    })
  },

  beforeMount() {
    this.get_price();
  },

  methods: {
    usDollar: Utill.usDollar,
    displayError(event) {
      const displayError = document.getElementById('card-errors')
      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ''
      }
    },

    async check_payment(){
      try {
        this.$isLoading(true);
        const result = await stripe.createToken(card);
        const p_result = await PostService.makePayment(result.token, this.currentUser).finally(() => {this.$isLoading(false)});
        if (p_result.data.status === "success"){
          this.$store.dispatch('alert/setAlert', { message: p_result.data.message, errors: null, color: 'success' }, { root: true });
          const user = p_result.data.data;
          this.$store.dispatch('auth/setCurrentUser', user, {root: true});
        }else {
          this.$store.dispatch('alert/setAlert', { message: p_result.data.message, errors: null, color: 'danger' }, { root: true });
        }
      }catch (e) {
        this.setAlert({message: e.message, errors: null, color: "danger"});
      }
    },

    async get_price(){
      try {
        this.$isLoading(true);
        const result = await PostService.get_price().finally(()=>{this.$isLoading(false)});
        const {data} = result;
        if (data.status === "success"){
          const {unit_amount, recurring, currency} = data.data;
          this.price.unit_amount = unit_amount;
          this.price.currency = currency;
          this.price.interval = recurring.interval;
        }else {
          this.setAlert({message: data.message, errors: null, color: "danger"});
        }
      } catch (e) {
        this.setAlert({message: e.message, errors: null, color: "danger"});
      }
    },

    ...mapActions("alert", ["setAlert"])
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
.pricing-card{
  padding: 3px 10px;
  background: #fff;
  border: 1px solid #C6C6C6;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 9px;
  a.purchase{
    background: #F10023;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
    border: 0;
    color: white;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  a.purchase:hover{
    color: white;
    background: #e70627;
  }
  small{
    font-size: 0.7em;
    color: gray;
  }
}
</style>