import axios from "axios";

const url = process.env.VUE_APP_API_URL;

class PostService {
    static async makePayment(token, currentUser){
        return await axios.post(`${url}/api/stripe/makePayment/`, {
            token,
            currentUser
        });
    }
    static async createCust(id, email, fullname) {
        return await axios.post(`${url}/api/stripe/`, {
            id, email, fullname
        });
    }
    static async createSubs(customerId, priceId) {
        return await axios.post(`${url}/api/stripe/subs`, {
            customerId, priceId
        });
    }
    static async delete(subscriptionId) {
        return await axios.post(`${url}/delete`, {
            subscriptionId,
        });
    }
    static async update_subs(subscription_id){
        return await axios.post(`${url}/api/stripe/update_subs`, {
            subscription_id,
        });
    }
    static async get_price(){
        return await axios.post(`${url}/api/stripe/getprice`);
    }
}
export default PostService;